const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/mobile/Home.vue')
  }, 
  {
    path: '/class',
    name: 'Class',
    component: () => import('../views/mobile/Class.vue')
  },
  {
    path: '/goodsDetail',
    name: 'GoodsDetail',
    component: () => import('../views/mobile/GoodsDetail.vue')
  },
  {
    path: '/goodsCzzy',
    name: 'GoodsCzzy',
    component: () => import('../views/mobile/GoodsCzzy.vue')
  },
  {
    path: '/goodsIsBo',
    name: 'GoodsIsBo',
    component: () => import('../views/mobile/GoodsIsBo.vue')
  },
  {
    path: '/watermark',
    name: 'Watermark',
    component: () => import('../views/mobile/Watermark.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/mobile/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/mobile/Register.vue')
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/mobile/Notice.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/mobile/Order.vue')
  },
  {
    path: '/orderDetail',
    name: 'OrderDetail',
    component: () => import('../views/mobile/OrderDetail.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/mobile/News.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/mobile/User.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('../views/mobile/Setting.vue')
  },
  {
    path: '/resetNickname',
    name: 'ResetNickname',
    component: () => import('../views/mobile/ResetNickname.vue')
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import('../views/mobile/ResetPwd.vue')
  },
  {
    path: '/resetPhone',
    name: 'ResetPhone',
    component: () => import('../views/mobile/ResetPhone.vue')
  },
  {
    path: '/secret',
    name: 'Secret',
    component: () => import('../views/mobile/Secret.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/mobile/Balance.vue')
  },
  {
    path: '/rechargeRecord',
    name: 'RechargeRecord',
    component: () => import('../views/mobile/RechargeRecord.vue')
  },
  {
    path: '/balanceRecord',
    name: 'BalanceRecord',
    component: () => import('../views/mobile/BalanceRecord.vue')
  },
  {
    path: '/gd',
    name: 'Gd',
    component: () => import('../views/mobile/Gd.vue')
  },
  {
    path: '/addGd',
    name: 'AddGd',
    component: () => import('../views/mobile/AddGd.vue')
  },
  {
    path: '/gdDetail',
    name: 'GdDetail',
    component: () => import('../views/mobile/GdDetail.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('../views/mobile/Collection.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/mobile/Contact.vue')
  },
  {
    path: '/people',
    name: 'People',
    component: () => import('../views/mobile/People.vue')
  },
  {
    path: '/question',
    name: 'Question',
    component: () => import('../views/mobile/Question.vue')
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('../views/mobile/Vip.vue')
  },
  {
    path: '/buildSite',
    name: 'BuildSite',
    component: () => import('../views/mobile/BuildSite.vue')
  },
  {
    path: '/buildSiteSubmit',
    name: 'BuildSiteSubmit',
    component: () => import('../views/mobile/BuildSiteSubmit.vue')
  },
  {
    path: '/website',
    name: 'Website',
    component: () => import('../views/mobile/Website.vue')
  },
  {
    path: '/resetFzPwd',
    name: 'ResetFzPwd',
    component: () => import('../views/mobile/ResetFzPwd.vue')
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import('../views/mobile/Forget.vue')
  },
  {
    path: '/gg',
    name: 'Gg',
    component: () => import('../views/mobile/Gg.vue')
  },
  {
    path: '/orderWithGoods',
    name: 'OrderWithGoods',
    component: () => import('../views/mobile/OrderWithGoods.vue')
  },
  {
    path: '/success',
    name: 'Success',
    component: () => import('../views/mobile/Success.vue')
  }
]

export default routes
