import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

import store from '../store'
import tool from '../utils/tool'

import mobileRoutes from './mobileRoutes'
// import pcRoutes from './pcRoutes'

Vue.use(VueRouter)

var _isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)

var inIndex1SkipPermissionsPath = ["/login", "/register", "/forget", '/goodsDetail', '/class', "/"]
var inIndex2SkipPermissionsPath = ["/login", "/register", "/forget"]

var routes
if (_isMobile) {
  routes = mobileRoutes
  let css = `
    html { font-size: calc(100vw / 7.5); }
    :root { --max-width: 100vw; --max-width-50: 50vw; ---max-width: -100vw; ---max-width-50: -50vw; }
  `
  let style = document.createElement('style')
  style.appendChild(document.createTextNode(css))
  document.head.appendChild(style)

} else {
  routes = mobileRoutes
  let maxWidget = window.screen.width
  maxWidget = maxWidget > 420 ? 420 : maxWidget
  let maxWidget50 = parseInt(maxWidget / 2);
  maxWidget50 = maxWidget50 + 'px'
  let maxWidget13 = maxWidget - parseInt(maxWidget * 0.13);
  maxWidget13 = maxWidget13 + 'px'
  maxWidget = maxWidget + 'px'
  let css = `
    html { font-size: calc(` + maxWidget + ` / 7.5); background-color: #f7f7f7; }
    #app { width: ` + maxWidget + `; margin: 0 auto; overflow: hidden; }
    :root { --max-width: ` + maxWidget + `; --max-width-50: ` + maxWidget50 + `; ---max-width: -` + maxWidget + `; ---max-width-50: -` + maxWidget50 + `; --max-width-13vw: ` + maxWidget13 + `; }
  `
  let style = document.createElement('style')
  style.appendChild(document.createTextNode(css))
  document.head.appendChild(style)
  // routes = pcRoutes
  // let css = `
  //   html { background-color: #f2f8fd; }
  //   /* 美化整个滚动条 */
  //   ::-webkit-scrollbar {
  //     width: 6px; /* 设置滚动条的宽度 */
  //     height: 6px;
  //     background-color: #f9f9f9; /* 滚动条的背景颜色 */
  //   }
    
  //   /* 美化滚动条轨道 */
  //   ::-webkit-scrollbar-track {
  //     background: #e1e1e1; /* 轨道的背景颜色 */
  //     border-radius: 4px; /* 轨道的圆角 */
  //   }
    
  //   /* 美化滚动条的滑块 */
  //   ::-webkit-scrollbar-thumb {
  //     background-color: #c1c1c1; /* 滑块的背景颜色 */
  //     border-radius: 4px; /* 滑块的圆角 */
  //   }
    
  //   /* 当滑块悬停或活动时的样式 */
  //   ::-webkit-scrollbar-thumb:hover {
  //     background: #a8a8a8; /* 悬停或活动状态下滑块的背景颜色 */
  //   }
  // `
  // let style = document.createElement('style')
  // style.appendChild(document.createTextNode(css))
  // document.head.appendChild(style)
}

store.dispatch('GetWebsiteInfo').then((res) => {
  if (res.SiteName) {
    document.title = res.SiteName
  }
})

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  
  let token = tool.data.get("USER_TOKEN")

  if (token && !store.state.token) {
    store.commit('SET_TOKEN', token)
    let userInfo = tool.data.get("APP_USER_INFO")
    store.commit('SET_USERINFO', userInfo)
  }
  if (token) {
    store.dispatch('GetUserInfo')
  }

  if (!token) {
    const isIndex = await store.dispatch('GetIsIndex')
    if (isIndex.info == 1 && inIndex1SkipPermissionsPath.includes(to.path)) {
      next()
      return false
    }
    if (isIndex.info != 1 && inIndex2SkipPermissionsPath.includes(to.path)) {
      next()
      return false
    }
    // if (_isMobile) {
      next({
        path: '/login'
      })
    // } else {
    //   next({
    //     path: '/'
    //   })
    // }
    return false
  }
  // if (!store.state.token) {
  //   store.commit('SET_TOKEN', token)
  //   let userInfo = tool.data.get("APP_USER_INFO")
  //   store.commit('SET_USERINFO', userInfo)
  // }
  // store.dispatch('GetUserInfo')

	next()
})


router.afterEach(() => {
	NProgress.done()
});

router.onError((error) => {
	NProgress.done();
  console.log(error)
});

export default router
