import axios from 'axios'
import tool from '@/utils/tool'
import router from '@/router'
import { Dialog } from 'vant-green'

const baseURL = process.env.NODE_ENV === 'development' ? "/api" : process.env.VUE_APP_API_BASEURL

axios.defaults.baseURL = baseURL

axios.defaults.timeout = 0

// HTTP request 拦截器
axios.interceptors.request.use(
  (config) => {
    let token = tool.data.get("USER_TOKEN");
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    if (config.method == 'get') {
      config.params.Lan = 'cn'
    }
    if (config.method == 'post') {
      config.data.Lan = 'cn'
    }
    Object.assign(config.headers, {
      "Content-Type": "multipart/form-data;"
    })
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// HTTP response 拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.data.error == 3) {
      router.replace({
        path: '/'
      })
    }
    if (response.data.error == 4) {
      Dialog.alert({
        type: 'danger',
        title: '提示',
        message: response.data.info,
        confirmButtonText: '确定'
      })
      
    }
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status == 404) {
        console.log(404)
      } else if (error.response.status == 500) {
        console.log(500)
      } else if (error.response.status == 401) {
        console.log(401)
        router.replace({
          path: '/'
        })
      } else {
        console.log('error')
      }
    } else {
      console.log('response error')
    }

    return Promise.reject(error.response);
  }
);

var http = {
  // 请求地址前缀
  baseURL: baseURL,

  /** get 请求
   * @param  {string} url 接口地址
   * @param  {object} params 请求参数
   * @param  {object} config 参数
   */
  get: function (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        params: params,
        ...config
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  /** post 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  post: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: url,
        data: data,
        ...config
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  /** put 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  put: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: url,
        data: data,
        ...config
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  /** patch 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  patch: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'patch',
        url: url,
        data: data,
        ...config
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  /** delete 请求
   * @param  {string} url 接口地址
   * @param  {object} data 请求参数
   * @param  {object} config 参数
   */
  delete: function (url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: url,
        data: data,
        ...config
      }).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      })
    })
  },

  /** jsonp 请求
   * @param  {string} url 接口地址
   * @param  {string} name JSONP回调函数名称
   */
  jsonp: function (url, name = 'jsonp') {
    return new Promise((resolve) => {
      var script = document.createElement('script')
      var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`
      script.id = _id
      script.type = 'text/javascript'
      script.src = url
      window[name] = (response) => {
        resolve(response)
        document.getElementsByTagName('head')[0].removeChild(script)
        try {
          delete window[name];
        } catch (e) {
          window[name] = undefined;
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }
}

export default http;